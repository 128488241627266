import React, { useEffect, useState } from "react";

import Button from "../components/button";
import SectionHeader from "./section-header";

import { API, graphqlOperation } from "aws-amplify";
import { createEmail } from "../graphql/mutations";
const initialState = { email: "" };

const CallToAction = () => {
  const [formState, setFormState] = useState(initialState);
  const [emails, setEmails] = useState([]);
  async function addEmail() {
    console.log("Adding email");
    console.log(formState.email);
    try {
      if (!formState.email) return;
      const email = { ...formState };
      setEmails([...emails, email]);
      setFormState(initialState);
      await API.graphql(graphqlOperation(createEmail, { input: email }));
    } catch (err) {
      console.log("error creating email:", err);
    }
  }

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  return (
    <div style={{ padding: "0rem 1rem", textAlign: "center" }}>
      <SectionHeader
        title="Stay Updated"
        description="We will launch in the next few months. Be among the firsts to join us and start saving soon."
      />

      <form>
        <label for="email">
          Please enter your Email address so we can contact you at when we are
          ready to launch
        </label>
        <br />
        <input
          onChange={(event) => setInput("email", event.target.value)}
          value={formState.email}
          type="text"
          id="email"
          name="email"
          placeholder="Your email address"
        />
        <br />
      </form>
      <Button>Get Early Access</Button>
    </div>
  );
};

export default CallToAction;
