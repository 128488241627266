import React from "react";

import { COLORS, BORDER_RADIUS, GRADIENT } from "../styles/constants";
import "../styles/button.css";

const Button = ({ clicked, children }) => (
  <button
    style={{
      padding: ".5rem 2.5rem",
      color: COLORS.lightWhite,
      fontWeight: 700,
      background: GRADIENT,
      borderRadius: BORDER_RADIUS,
      borderWidth: 0,
      cursor: "pointer",
    }}
    onClick={clicked}
  >
    {children}
  </button>
);

export default Button;
