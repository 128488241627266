import React from "react";
import Amplify from "aws-amplify";
import awsExports from "./../aws-exports";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import Content from "../components/content";
import CallToAction from "../components/cta";

Amplify.configure(awsExports);
const IndexPage = () => (
  <Layout>
    <SEO />
    <Hero />
    <Content />
    <CallToAction />
  </Layout>
);

export default IndexPage;
