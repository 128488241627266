import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Button from "../components/button";
import headerImage from "../images/header.png";
import MockupContent from "./image";
import mockupFrame from "../images/mockup-frame.png";

import { API, graphqlOperation } from "aws-amplify";
import { createEmail } from "../graphql/mutations";

const initialState = { email: "" };

const Header = ({ siteTitle }) => {
  const [formState, setFormState] = useState(initialState);
  const [emails, setEmails] = useState([]);
  async function addEmail() {
    console.log("Adding email");
    console.log(formState.email);
    try {
      if (!formState.email) return;
      const email = { ...formState };
      setEmails([...emails, email]);
      setFormState(initialState);
      await API.graphql(graphqlOperation(createEmail, { input: email }));
    } catch (err) {
      console.log("error creating email:", err);
    }
  }

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "4rem 1rem",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${headerImage})`,
          position: "absolute",
          top: 0,
          zIndex: -5,
          height: "100vh",
          width: "100vw",
          opacity: 0.5,
        }}
      />
      <h1 style={{ textAlign: "center" }}>Cash return</h1>
      <p style={{ textAlign: "center", maxWidth: 440 }}>
        Cash return helps you get money back after making your purchases.
      </p>
      <form style={{ textAlign: "center", maxWidth: 440 }}>
        <label for="email">
          Please enter your Email address so we can contact you when we are
          ready to launch
        </label>

        <input
          onChange={(event) => setInput("email", event.target.value)}
          value={formState.email}
          type="text"
          id="email"
          name="email"
          placeholder="Your email address"
        />
        <br />
      </form>
      <Button clicked={addEmail}>Get Early Access</Button>
      <div style={{ margin: 60, width: `250px`, position: "relative" }}>
        <div style={{ clipPath: "inset(2% 5% round 2% 5%)" }}>
          <MockupContent />
        </div>
        <div
          style={{
            position: "absolute",
            width: "250px",
            top: 0,
          }}
        >
          <img
            src={mockupFrame}
            alt="outlines of shapes and confetti in the background "
          />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
