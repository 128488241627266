import React from "react";

import feature from "../images/feature.png";
import SectionHeader from "./section-header";
import { COLORS } from "../styles/constants";

const Content = () => (
  <div style={{ padding: "4rem 1rem 1 rem", textAlign: "center" }}>
    <SectionHeader
      title="How the idea came to us"
      description="Last year my wife and I got married down here in Tassie. We are both from oversea and wanted our parents to come. My parents booked their airfare first, and we booked their hotels. A few weeks later her parents bought theirs, and we booked them in the same hotels on hotels.com, but the overall price was $350 cheaper. I took a few screenshot sent them to hotels.com and got the $350 back. I thought this was such a great thing. I felt the need to share it with everyone."
    />
    {/* When we got married we invited our parents to Tassie and we booked my */}
    <SectionHeader
      title="Get up to hundreds of dollars back"
      description="Simply send us your receipt if we find a cheaper price before the end of the price guarantee we will provide you with all the information you need to apply for a refund."
    />
    {/* // Add something like: you can't wait? start sending your recipes to
    receipts@cashreturn.com.au */}
    {/* <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div>
        <h3>What you need to Start</h3>
        <p style={{ color: COLORS.gray }}>
          Includes plugins for analytics, building sitemaps, and optimizing
          images
        </p>
      </div>
      <div>
        <img src={feature} alt="a blank card floating over colorful graphics" />
      </div>
    </content> */}
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(540px, 770px))",
      }}
    ></content>
  </div>
);

export default Content;
